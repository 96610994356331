
//==========  List styles  ==========//

// list item styles
%default-list {
    padding: 0 0 0 $block-padding;
    margin: 0 0 $paragraph-margin;
}

%default-ul {
    list-style: disc;
    @extend %default-list;
}

%default-ol {
    list-style: decimal;
    @extend %default-list;
}

// list item reset
%reset-ul {
    list-style: none;
    padding: 0;
    margin: 0;
}


//==========  Buttons and default styles  ==========//

// base button styles
// extend for different button styles
%button {
    display: inline-block;
    height: $button-height;
    line-height: $button-height;
    border: none;
    background: $color-primary;
    color: $color-white;
    padding: 0 ($site-padding * 1.5);
    text-align: center;
    transition: $transition;
    @include uppercase($font-size-body - .3);
}

%hover {
    background: $color-secondary;
    color: $color-white;
}

// extend for default .read-more
%read-more {
    @extend %button;

    &:hover {
        @extend %hover;
    }
}

